import React from 'react'
import Layout from '../../../components/layout'
import { Link } from 'gatsby'

const Kids = () => (
  <Layout>
    <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <h1>Kids</h1>
      </div>
    </section>
    <section className="section-padding biq-bg-white biq-text-black select-none">
      <div className="container">
        <h2 className="text-left mt-6">The BIQ is my BFF</h2>
        <div className="flex biq-text-md mt-6">
          The Breathing is my best friend. Why? Because strong lungs mean strong
          you. Think of all the things you do to be healthy: Eat vegetables,
          drink water, see your doctor, get shots, exercise, take vitamins…now
          you need to add one more thing to that list: Breathing Exercises! Kids
          who do breathing exercises have less asthma, don’t get sick as much,
          and have stronger lungs to defend themselves against Covid.
        </div>
        <div className="flex biq-text-md mt-6">
          There are two types of breathing exercises: Ones to work out your
          lungs and the muscles that pump them up. And ones that help you calm
          your body and brain.
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            But the first thing to do is take your Breathing IQ. First try it on
            you Then try it on your brother, sister, mom dad or a friend!
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <ol>
            <li>Ask them to take a really big inhale.</li>
            <li>
              Do they move up and down? Or do they get wider and then narrower
              on the exhale. (hint: most grownups move up and down, they are
              Vertical breathers). If it seems like they are a little of both
              then they are Hybrids.
            </li>
            <li>Get a cloth measuring tape.</li>
            <li>
              Find the bottom of their ribs in the front of their body, put the
              measuring tape here and wrap it around their body. Make sure it’s
              snug.
            </li>
            <li>
              Ask them for a big inhale, write down that number. Now a big
              exhale, write down that number.
            </li>
          </ol>
        </div>
        <div className="flex biq-text-md mt-6">
          Now use our{' '}
          <Link to="/biq" className="font-bold biq-text-prim mx-2">
            calculator
          </Link>{' '}
          to find out that grade!
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            The good news is that a bad grade doesn’t mean you are in trouble or
            have extra work. It just shows you where you are so you can measure
            over and over and see how good you get. Your BIQ will show you how
            strong your lungs get, it’s your best friend.
          </span>
        </div>
        <h3>Fun Facts</h3>
        <div className="flex biq-text-md mt-6">
          <span>
            <ul>
              <li>
                The biggest part of your lungs are in the middle of your body
                (not the top!).
              </li>
              <li>
                The diaphragm is a muscle the size of a Frisbee (or small
                pizza!)
              </li>
              <li>
                On the inhale the diaphragm pushes your ribs open and widens the
                middle of your body.
              </li>
              <li>On the exhale your body flattens or narrows. You deflate!</li>
              <li>
                The best way to calm down is to lie on your back and belly
                breathe, through your nose.
              </li>
            </ul>
          </span>
        </div>
      </div>
    </section>
    <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
          // style={{
          //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: 'darken',
          //   backgroundPositionY: '1',
          // }}
        >
          <div className="flex absolute">
            {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
          </div>
          <div className="container">
            {/* BIQ  */}
            <div className="flex flex-row flex-wrap my-24">
              {/* <div className="flex w-full xl:w-1/2"> */}
              {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section>
  </Layout>
)

export default Kids
